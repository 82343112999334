<template>
  <v-snackbar
    v-model="sb.mostrar"
    :bottom="true"    
    :left="false"
    :multi-line="sb.multiline"
    :right="false"
    :timeout="sb.timeout"
    :color="sb.color"
    :top="false"
    :vertical="false"
    >
    {{ sb.mensaje }}
    <template v-slot:action="{ attrs }">
        <v-btn
            text
            dark
            v-bind="attrs"
            @click="sb.mostrar = false"
        >
            Close
        </v-btn>
    </template>
    

    
    </v-snackbar>
</template>

<script>
export default {
    name: 'SnackbarMensajes',
    props: {
        sb: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        }
    },
}
</script>

<style>

</style>