import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import store from '../store/store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token dashboard: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/pedidos',
    name: 'Pedidos a domicilio',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/GridPedidos.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token pedidos: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/menuinventario',
    name: 'MenuInventario',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/MenuInventario.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token pedidos: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/productos',
    name: 'Productos',
    component: () => import('../views/GridProductos.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token productos: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/surtir',
    name: 'Surtir pedidos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Surtir.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token surtir: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/buscarenbodega',
    name: 'Buscar en bodega',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/BuscarEnBodega.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token surtir: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/registro_ventas',
    name: 'Confirmar Ventas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ConfirmarVentas.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token surtir: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/inbox',
    name: 'Inbox',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Inbox.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token surtir: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/repartir',
    name: 'Repartir',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Dashboard,
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token surtir: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter(to, from, next) {
      //console.log('token login: ' + store.state.accessToken);
      next()
    },
  },
  {
    path: '/rep-ventas',
    name: 'Reporte de Ventas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/RepVentas.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/rep-ventas-semanal',
    name: 'Reporte de Ventas Semanal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/RepVentasSemanal.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/rep-bonos',
    name: 'Reporte de Bonos Semanales',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/RepBonos.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/asigna-repartidor',
    name: 'Asignar Repartidor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AsignaRepartidor.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/inventario',
    name: 'Inventario',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Inventario.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      //console.log('token pedidos: ' + store.state.accessToken);
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/rep-contactos-dia',
    name: 'Contactos del día',
    component: () => import('../views/RepContactosDia.vue'),
    beforeEnter(to, from, next) {
      if(!store.state.accessToken) store.dispatch('tryAutoLogin')
      if (store.state.accessToken) {
        next()
      } else {
        next('/login')
      }
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router