import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import ApiService from '@/services/ApiService.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: null,
    accessToken: null,
    userId: null,
    userName: null,
    rbac: null,
    noleidoscount: null,
    interval: null,
  },
  getters: {
    hasPermission: (state) => (permiso) => {
      return permiso === '' ? true : state.rbac.find(item => item === permiso)
     
    },
    token(state) {
      return state.accessToken
    },
    user (state) {
      return state.userId
    },
    username (state) {
      return state.userName
    },
    isAuthenticated (state) {
      return state.accessToken !== null
    },
    version (state) {
      return state.version
    },
    noleidoscount (state) {
      return state.noleidoscount;
    }
  },
  mutations: {
    authUser (state, data) {
      state.accessToken = data.accessToken
      state.userId = data.userId
      state.userName = data.userName
      state.rbac = data.rbac
    },
    clearAuthData (state) {
      state.accessToken = null;
      state.userId = null;
      state.rbac = null;
      state.noleidoscount = 0;
    },
    setVersion (state, version) {
      state.version = version
    },
    habilitaInterval( state, objeto ) {
      state.interval = objeto;
    },
    deshabilitaInterval( state ) {
      clearInterval(state.interval);
    },
    updateNoleidoscount( state, count ) {
      state.noleidoscount = count;
    }
  },
  actions: {
    signIn: ({ dispatch, commit }, data) => {
      localStorage.setItem('accessToken', data.accessToken)
      localStorage.setItem('userId', data.userId)
      localStorage.setItem('userName', data.userName)
      localStorage.setItem('rbac', JSON.stringify(data.rbac))

      commit('authUser', {
        accessToken: data.accessToken,
        userId: data.userId,
        userName: data.userName,
        rbac: data.rbac
      });
      dispatch('getMensajesNoLeidosCount');
      dispatch('habilitaInterval');
      router.replace('/');

    },
    signOut: ({ commit }) => {
      commit('clearAuthData');
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userId')
      localStorage.removeItem('userName')
      localStorage.removeItem('rbac')
      commit('deshabilitaInterval');
      router.replace('/login')
    },
    tryAutoLogin ({ dispatch, commit }) {
      const token = localStorage.getItem('accessToken')
      if (!token) {
        //router.replace('/login')
        return
      }
      const userId = localStorage.getItem('userId')
      const userName = localStorage.getItem('userName')
      const rbac = JSON.parse(localStorage.getItem('rbac'))

      commit('authUser', {
        accessToken: token,
        userId: userId,
        userName: userName,
        rbac: rbac,
      });

      dispatch('getMensajesNoLeidosCount');

      dispatch('habilitaInterval');
    },
    getMensajesNoLeidosCount: ({ commit, state }) => {
      ApiService.getMensajesNoLeidosCount(state.accessToken)
      .then(response => {
          if (response.status == 200) {
              commit('updateNoleidoscount', response.data.count);
          }
      })
      .catch(error => {
          if (error.response) console.log(`Error al obtener noleidoscount ${error.response.status} - ${error.response.statusText}`, 'error', true)
          else console.log(`Error al obtener noleidoscount ${error.message}`, 'error', true)
      })
    },
    habilitaInterval: ({ commit, state }) => {
      commit('habilitaInterval', setInterval(() => {
        ApiService.getMensajesNoLeidosCount(state.accessToken)
        .then(response => {
            if (response.status == 200) {
                commit('updateNoleidoscount', response.data.count);
            }
        })
        .catch(error => {
            if (error.response) console.log(`Error al obtener noleidoscount ${error.response.status} - ${error.response.statusText}`, 'error', true)
            else console.log(`Error al obtener noleidoscount ${error.message}`, 'error', true)
        })
      }, 180000)
    );

      
    },   
  },
  modules: {}
})