import axios from 'axios'

const BASEURL = process.env.VUE_APP_API_URL;

const apiClient = axios.create({
    baseURL: BASEURL,
    withCredentials: false,
})

export default {
    eltoken: '',
    baseURL: BASEURL,
    getDataTableConfig(token,options){
        //let str = Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&');
        //let str = '&sort=-id&page='+options.page+'&per-page='+options.itemsPerPage;
        //?sort=id&access-token='+token+'&'+str+filtro
        let orderBy = '';
        options.sortBy.forEach((field,idx,arr) => {
            orderBy = (options.sortDesc[idx] ? '-' : '') + field
        })
        return {
            params: {
                'access-token': token,
                sort: orderBy,
                page: options.page,
                'per-page': options.itemsPerPage
            }
        }
    },
    getProductos(token,options,search){
        let config = this.getDataTableConfig(token, options)

        // filtros
        if(search.length > 0) config.params.nombre = search

        return apiClient.get('/adomicilioproductos',config) 
        
    },
    getImagenProducto(token,id){
        const config = {
            params: {
                'access-token': token,
                fields: 'id',
                expand: 'foto1_base64'
            }
        }
        return apiClient.get('/adomicilioproductos/'+id,config)
    },
    grabaProducto(token,data) {
        if(data.id == -1) return apiClient.post('/adomicilioproductos?access-token='+token,data)
        else return apiClient.put('/adomicilioproductos/'+data.id+'?access-token='+token,data)
    },
    borrarProducto(token,id) {
        return apiClient.delete('/adomicilioproductos/'+id+'?access-token='+token)
    },
    getPedidos(token,options,filtros){
        let config = this.getDataTableConfig(token,options)

        // filtros
        if(filtros.cliente_nombre) config.params.cliente_nombre = filtros.cliente_nombre;
        if(filtros.idpedido) config.params.id = filtros.idpedido;
        if(filtros.vendedor_nombre) config.params.vendedor_nombre = filtros.vendedor_nombre;

        return apiClient.get('/pedidos',config)
    },
    getSalidas(token,options,filtros){
        let config = this.getDataTableConfig(token,options)

        // filtros
        if(filtros.idrepartidor) config.params.idrepartidor = filtros.idrepartidor;
        if(filtros.fecha) config.params.fecha = filtros.fecha;
        return apiClient.get('/salidas',config)
    },
    grabaPedido(token,data,id) {
        if(id == -1) return apiClient.post('/pedidos?access-token='+token,data)
        else return apiClient.put('/pedidos/'+id+'?access-token='+token,data)
    },
    grabaProductosPedido(token,data){
        return apiClient.patch('/pedidos/'+data.idpedido+'/productos?access-token='+token,data)
    },
    borrarPedido(token,id) {
        return apiClient.delete('/pedidos/'+id+'?access-token='+token)
    },
    authUser(data){
        return apiClient.post('/users/authuser',data)
    },
    getProductosPendientes(token,verRecolectados){
        return apiClient.get('/productopedidos/pendientes?access-token='+token+'&ver_recolectados='+verRecolectados)
    },
    setProductoPedidoRecolectadoBodega(token,id,datos){
        return apiClient.put(`/productopedidos/${id}/recolectarenbodega?access-token=${token}`,datos)
    },
    grabaProductoPedido(token,id,payload){
        if(id == -1) return apiClient.post(`/productopedidos?access-token=${token}`,payload)
        return apiClient.put(`/productopedidos/${id}?access-token=${token}`,payload)
    },
    getRepartidores(token){
        const params = {
            params:{
                'access-token': token,
            }
        }
        return apiClient.get('/users/repartidores',params)
    },
    getVendedores(token){
        const params = {
            params:{
                'access-token': token,
            }
        }
        return apiClient.get('/users/vendedores',params)
    },
    getSemanas(token){
        let payload = {
            
        }
        return apiClient.post(`/productopedidos/semanas?access-token=${token}`,payload)
    },
    getCatalogos(token) {
        return apiClient.all([this.getRepartidores(token),this.getVendedores(token)])
    },
    getEtiquetas(token,filtros){
        let payload = {
            fecha_entrega: filtros.fecha_entrega,
            confirmado: filtros.confirmado
        }
        if(filtros.idpedido.length > 0) payload.idpedido = filtros.idpedido;
        return apiClient.post(`/pedidos/etiquetas?access-token=${token}`,payload)
    },
    getPdf(token,action,payload){
        return apiClient.post(`${action}?access-token=${token}`,payload)
    },
    getPedidosPendientes(token,filtros){
        let config = {
            params:{
                'access-token': token,
                fecha_entrega: filtros.fecha_entrega,
                confirmado: filtros.confirmado
            }
        }
        if(filtros.idpedido.length > 0) config.params.idpedido = filtros.idpedido;
        return apiClient.get('/pedidos/pendientes',config)
    },
    getPedidosDelDia(token,filtros){
        let config = {
            params:{
                'access-token': token,
                fecha: filtros.fecha,
            }
        }
        return apiClient.get('/pedidos/pedidosdeldia',config)
    },
    getPedidosEntregas(token,options,filtros){
        let config = this.getDataTableConfig(token,options)

        // filtros
        config.params.confirmado = filtros.confirmado;
        config.params.cancelado = 0;
        if(filtros.idpedido.length > 0) config.params.idpedido = filtros.idpedido;
        if(filtros.vendedor_nombre.length > 0) config.params.vendedor_nombre = filtros.vendedor_nombre;
        if(filtros.idrepartidor) config.params.idrepartidor = filtros.idrepartidor;

        return apiClient.get('/pedidos/pendientes',config)
    },
    grabaSalida(token,id,payload){
        if(id == -1) return apiClient.post(`/salidas?access-token=${token}`,payload)
        return apiClient.put(`/salidas/${id}?access-token=${token}`,payload)
    },
    getMensajesNoLeidosCount(token){
        let config = {
            params:{
                'access-token': token,
            }
        }
        return apiClient.get('/mensajes/noleidoscount',config)
    },
    getMensajes(token){
        let config = {
            params:{
                'access-token': token,
            }
        }
        return apiClient.get('/mensajes',config)
    },
    marcarLeido(token,id){
        return apiClient.patch(`/mensajes/${id}/marcarleido?access-token=${token}`,null)
    },
    getPrecioProducto(token, claveproducto){
        let config = {
            params:{
                'access-token': token,
                claveproducto: claveproducto,
                fields: 'clavesucursal,claveproducto,idproducto,descripcion,precio',
            }
        }
        return apiClient.get('/productos',config)
    },
    getSucursales(token){
        let config = {
            params:{
                'access-token': token,
                fields: 'clavesucursal,nombre',
            }
        }
        return apiClient.get('/sucursal',config)
    },
}