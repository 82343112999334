export default {
    items: [
        { title: 'Home', icon: 'mdi-home', color: 'red lighten-1', link: '/', permiso: '', dash: false, textclass: 'white--text headline'  },
        { title: 'Pedidos', icon: 'mdi-cart-arrow-down', color: 'orange', link: 'pedidos', permiso: 'crear_pedido', dash: true, textclass: 'white--text headline' },
        { title: 'Buscar en Bodega', icon: 'mdi-basket-fill', color: 'pink lighten-1', link: 'buscarenbodega', permiso: 'surtir_pedido', dash: true, textclass: 'white--text headline' },
        { title: 'Surtir Pedidos', icon: 'mdi-shopping', color: 'red lighten-1', link: 'surtir', permiso: 'surtir_pedido', dash: true, textclass: 'white--text headline' },
        { title: 'Asignar Repartidor', icon: 'mdi-map-marker-radius-outline', color: 'teal lighten-1', link: 'asigna-repartidor', permiso: 'surtir_pedido', dash: true, textclass: 'white--text headline' },
        { title: 'Repartir', icon: 'mdi-truck-delivery', color: 'brown lighten-1', link: 'entregas', permiso: 'entregar_pedido', dash: true, textclass: 'white--text headline' },
        { title: 'Confirmar Ventas', icon: 'mdi-checkbox-multiple-marked-circle-outline', color: 'purple lighten-1', link: 'registro_ventas', permiso: 'administrar_productos', dash: true, textclass: 'white--text headline' },
        { title: 'Productos', icon: 'mdi-shoe-heel', color: 'light-blue lighten-1', link: 'productos', permiso: 'administrar_productos', dash: true, textclass: 'white--text headline' },
        { title: 'Inventario', icon: 'mdi-warehouse', color: 'light-blue lighten-1', link: 'menuinventario', permiso: '', dash: true, textclass: 'white--text headline' },
    ],
}