<template>
  <v-app>
     <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list dense>
          <v-list-item v-for="item in items" :key="item.id" link :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-if="reportes.length > 0"
            prepend-icon="mdi-file-document-outline"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Reportes</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="(item, i) in reportes" :key="i" link :to="item.link" class="pl-10">
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
              
            </v-list-item>

          </v-list-group>


          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Versión {{ version }}</v-list-item-title>
              
            </v-list-item-content>
          </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-app-bar
      app
      color="indigo"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="pl-0">{{ vue_app_title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="auth" text>
        {{ username }}
      </v-btn>
      <v-badge v-if="auth" 
        :content="noleidoscount"
        :value="noleidoscount"
        color="green"
        overlap
        @click="inbox"
      >
        <v-icon @click="inbox">mdi-bell</v-icon>
      </v-badge>
      
       <v-btn icon v-if="auth" @click="signOut">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
      <!-- <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="n in 5"
            :key="n"
            @click="() => {}"
          >
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    
    </v-app-bar>

    <v-main>
      <transition name="slide-right">
      <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import menuitems from '@/menuitems.js'
import menureportes from '@/menureportes.js'
import router from '@/router'

const ITEMS = menuitems.items;
const REPORTES = menureportes.items;
const VERSION = process.env.VUE_APP_VERSION;

export default {
  name: 'App',

  data: () => ({
    drawer: false,
  }),
  computed: {
    vue_app_title() {
      return process.env.VUE_APP_TITLE;
    },
    version(){
      return this.$store.getters.version
    },
    auth () {
      return this.$store.getters.isAuthenticated
    },
    noleidoscount () {
      return this.$store.getters.noleidoscount;
    },
    items() {
      return ITEMS
    },
    reportes() {
      return REPORTES
    },
    username() {
      return this.$store.getters.username;
    }
  },
  methods: {
    inbox() {
      router.replace('/inbox');
    },
    signOut() {
       this.$store.dispatch('signOut')
    }
  },
  created () {
    this.$store.commit('setVersion',VERSION);
    if(!this.$store.getters.token) this.$store.dispatch('tryAutoLogin')
    console.log('VERSION: ' + process.env.VUE_APP_VERSION);
    console.log('API URL: ' + process.env.VUE_APP_API_URL);
    console.log('NODE_ENV: ' + process.env.NODE_ENV);
    console.log('VUE_APP_PACKAGE_VERSION: ' + process.env.VUE_APP_PACKAGE_VERSION);
    
  }
};
</script>
